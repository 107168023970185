import React, { createContext, useContext, useState } from 'react'
import { PopupContextType } from './types'

const PopupContext = createContext<PopupContextType | undefined>(undefined)

export const PopupProvider: React.FC = ({ children }) => {
  const [popup, setPopup] = useState(false)
  const [popupData, setPopupData] = useState({
    video: '',
    title: '',
    desc: '',
    image: '',
  })

  return (
    <PopupContext.Provider value={{ popup, popupData, setPopup, setPopupData }}>
      {children}
    </PopupContext.Provider>
  )
}

export const usePopup = (): PopupContextType => {
  const popup = useContext(PopupContext)

  if (popup) {
    return popup
  }

  throw new Error('useApp must be used within a AppContextProvider.')
}
